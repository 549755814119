.z-padding {
  padding: @padding-md;
}

.z-padding-top {
  padding-top: @padding-md;
}

.z-padding-right {
  padding-right: @padding-md;
}

.z-padding-bottom {
  padding-bottom: @padding-md;
}

.z-padding-left {
  padding-left: @padding-md;
}

.z-padding-xss {
  padding: @padding-xss;
}

.z-padding-top-xss {
  padding-top: @padding-xss;
}

.z-padding-right-xss {
  padding-right: @padding-xss;
}

.z-padding-bottom-xss {
  padding-bottom: @padding-xss;
}

.z-padding-left-xss {
  padding-left: @padding-xss;
}

.z-padding-xs {
  padding: @padding-xs;
}

.z-padding-top-xs {
  padding-top: @padding-xs;
}

.z-padding-right-xs {
  padding-right: @padding-xs;
}

.z-padding-bottom-xs {
  padding-bottom: @padding-xs;
}

.z-padding-left-xs {
  padding-left: @padding-xs;
}

.z-padding-sm {
  padding: @padding-sm;
}

.z-padding-top-sm {
  padding-top: @padding-sm;
}

.z-padding-right-sm {
  padding-right: @padding-sm;
}

.z-padding-bottom-sm {
  padding-bottom: @padding-sm;
}

.z-padding-left-sm {
  padding-left: @padding-sm;
}

.z-padding-lg {
  padding: @padding-lg;
}

.z-padding-top-lg {
  padding-top: @padding-lg;
}

.z-padding-right-lg {
  padding-right: @padding-lg;
}

.z-padding-bottom-lg {
  padding-bottom: @padding-lg;
}

.z-padding-left-lg {
  padding-left: @padding-lg;
}

.z-margin {
  margin: @margin-md;
}

.z-margin-top {
  margin-top: @margin-md;
}

.z-margin-right {
  margin-right: @margin-md;
}

.z-margin-bottom {
  margin-bottom: @margin-md;
}

.z-margin-left {
  margin-left: @margin-md;
}

.z-margin-xss {
  margin: @margin-xss;
}

.z-margin-top-xss {
  margin-top: @margin-xss;
}

.z-margin-right-xss {
  margin-right: @margin-xss;
}

.z-margin-bottom-xss {
  margin-bottom: @margin-xss;
}

.z-margin-left-xss {
  margin-left: @margin-xss;
}

.z-margin-xs {
  margin: @margin-xs;
}

.z-margin-top-xs {
  margin-top: @margin-xs;
}

.z-margin-right-xs {
  margin-right: @margin-xs;
}

.z-margin-bottom-xs {
  margin-bottom: @margin-xs;
}

.z-margin-left-xs {
  margin-left: @margin-xs;
}

.z-margin-sm {
  margin: @margin-sm;
}

.z-margin-top-sm {
  margin-top: @margin-sm;
}

.z-margin-right-sm {
  margin-right: @margin-sm;
}

.z-margin-bottom-sm {
  margin-bottom: @margin-sm;
}

.z-margin-left-sm {
  margin-left: @margin-sm;
}

.z-margin-lg {
  margin: @margin-lg;
}

.z-margin-top-lg {
  margin-top: @margin-lg;
}

.z-margin-right-lg {
  margin-right: @margin-lg;
}

.z-margin-bottom-lg {
  margin-bottom: @margin-lg;
}

.z-margin-left-lg {
  margin-left: @margin-lg;
}

.z-flex-gap {
  gap: @padding-md;
}

.z-flex-gap-sm {
  gap: @padding-sm;
}

.z-flex-gap-xs {
  gap: @padding-xs;
}

.z-flex-gap-xss {
  gap: @padding-xss;
}