@import '~antd/lib/style/themes/index.less';
@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';

@import './default.variables.less';
@layout-header-background: #FFF;
@layout-body-background  : #FAFAFA;
@layout-sider-background : #23151C;

@menu-dark-bg                      : @layout-sider-background;
@menu-dark-inline-submenu-bg       : @layout-sider-background;
@menu-dark-highlight-color         : @primary-color;
@menu-dark-selected-item-icon-color: @primary-color;
@menu-dark-item-active-bg          : @layout-sider-background;

@tooltip-color : #FFF;
@tooltip-bg    : @grey-8;


.ant-menu>.z-sider-menu-dark-divider-color {
  border-color    : #352a2f;
  background-color: #352a2f;
}

@import './definitions/ant';
@import './default.gradient.less';
@import './definitions/zay';

.z-bg-default {
  background-color: @body-background;
}

.z-landing-page-image-container {
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center;
  background-image   : none;

  @media (min-width: 992px) {
    background-image: url('https://res.cloudinary.com/zayservice/image/upload/v1629096955/logos%20-%20zaycare/Splash/Zaycare_splash_v2_SMALL_n4co1n.jpg');
  }
}

.z-text-background-brush-brand-color {
  &::before {
    background-color: #FDDCEC;
  }
}

.z-app-logo {
  height: 30px;
  width : auto;
}