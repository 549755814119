.z-ant-select-readOnly {
  .ant-select-selector {
    background-color: @body-background;
    color           : @text-color;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: @body-background;
    color           : @text-color;
  }
}