// Grey
.z-grey-1 {
  color: @grey-1;
}

.z-grey-2 {
  color: @grey-2;
}

.z-grey-3 {
  color: @grey-3;
}

.z-grey-4 {
  color: @grey-4;
}

.z-grey-5 {
  color: @grey-5;
}

.z-grey-6 {
  color: @grey-6;
}

.z-grey-7 {
  color: @grey-7;
}

.z-grey-8 {
  color: @grey-8;
}

.z-grey-9 {
  color: @grey-9;
}

.z-grey-10 {
  color: @grey-10;
}

.z-grey-11 {
  color: @grey-10;
}

// Primary
.z-primary-1 {
  color: @primary-1;
}

.z-primary-2 {
  color: @primary-2;
}

.z-primary-3 {
  color: @primary-3;
}

.z-primary-4 {
  color: @primary-4;
}

.z-primary-5 {
  color: @primary-5;
}

.z-primary-6 {
  color: @primary-6;
}

.z-primary-7 {
  color: @primary-7;
}

.z-primary-8 {
  color: @primary-8;
}

.z-primary-9 {
  color: @primary-9;
}

.z-primary-10 {
  color: @primary-10;
}

// Red
.z-red-1 {
  color: @red-1;
}

.z-red-2 {
  color: @red-2;
}

.z-red-3 {
  color: @red-3;
}

.z-red-4 {
  color: @red-4;
}

.z-red-5 {
  color: @red-5;
}

.z-red-6 {
  color: @red-6;
}

.z-red-7 {
  color: @red-7;
}

.z-red-8 {
  color: @red-8;
}

.z-red-9 {
  color: @red-9;
}

.z-red-10 {
  color: @red-10;
}

// Volcano
.z-volcano-1 {
  color: @volcano-1;
}

.z-volcano-2 {
  color: @volcano-2;
}

.z-volcano-3 {
  color: @volcano-3;
}

.z-volcano-4 {
  color: @volcano-4;
}

.z-volcano-5 {
  color: @volcano-5;
}

.z-volcano-6 {
  color: @volcano-6;
}

.z-volcano-7 {
  color: @volcano-7;
}

.z-volcano-8 {
  color: @volcano-8;
}

.z-volcano-9 {
  color: @volcano-9;
}

.z-volcano-10 {
  color: @volcano-10;
}

// Orange
.z-orange-1 {
  color: @orange-1;
}

.z-orange-2 {
  color: @orange-2;
}

.z-orange-3 {
  color: @orange-3;
}

.z-orange-4 {
  color: @orange-4;
}

.z-orange-5 {
  color: @orange-5;
}

.z-orange-6 {
  color: @orange-6;
}

.z-orange-7 {
  color: @orange-7;
}

.z-orange-8 {
  color: @orange-8;
}

.z-orange-9 {
  color: @orange-9;
}

.z-orange-10 {
  color: @orange-10;
}

// Gold
.z-gold-1 {
  color: @gold-1;
}

.z-gold-2 {
  color: @gold-2;
}

.z-gold-3 {
  color: @gold-3;
}

.z-gold-4 {
  color: @gold-4;
}

.z-gold-5 {
  color: @gold-5;
}

.z-gold-6 {
  color: @gold-6;
}

.z-gold-7 {
  color: @gold-7;
}

.z-gold-8 {
  color: @gold-8;
}

.z-gold-9 {
  color: @gold-9;
}

.z-gold-10 {
  color: @gold-10;
}

// Yellow
.z-yellow-1 {
  color: @yellow-1;
}

.z-yellow-2 {
  color: @yellow-2;
}

.z-yellow-3 {
  color: @yellow-3;
}

.z-yellow-4 {
  color: @yellow-4;
}

.z-yellow-5 {
  color: @yellow-5;
}

.z-yellow-6 {
  color: @yellow-6;
}

.z-yellow-7 {
  color: @yellow-7;
}

.z-yellow-8 {
  color: @yellow-8;
}

.z-yellow-9 {
  color: @yellow-9;
}

.z-yellow-10 {
  color: @yellow-10;
}

// Lime
.z-lime-1 {
  color: @lime-1;
}

.z-lime-2 {
  color: @lime-2;
}

.z-lime-3 {
  color: @lime-3;
}

.z-lime-4 {
  color: @lime-4;
}

.z-lime-5 {
  color: @lime-5;
}

.z-lime-6 {
  color: @lime-6;
}

.z-lime-7 {
  color: @lime-7;
}

.z-lime-8 {
  color: @lime-8;
}

.z-lime-9 {
  color: @lime-9;
}

.z-lime-10 {
  color: @lime-10;
}

// Green
.z-green-1 {
  color: @green-1;
}

.z-green-2 {
  color: @green-2;
}

.z-green-3 {
  color: @green-3;
}

.z-green-4 {
  color: @green-4;
}

.z-green-5 {
  color: @green-5;
}

.z-green-6 {
  color: @green-6;
}

.z-green-7 {
  color: @green-7;
}

.z-green-8 {
  color: @green-8;
}

.z-green-9 {
  color: @green-9;
}

.z-green-10 {
  color: @green-10;
}

// Cyan
.z-cyan-1 {
  color: @cyan-1;
}

.z-cyan-2 {
  color: @cyan-2;
}

.z-cyan-3 {
  color: @cyan-3;
}

.z-cyan-4 {
  color: @cyan-4;
}

.z-cyan-5 {
  color: @cyan-5;
}

.z-cyan-6 {
  color: @cyan-6;
}

.z-cyan-7 {
  color: @cyan-7;
}

.z-cyan-8 {
  color: @cyan-8;
}

.z-cyan-9 {
  color: @cyan-9;
}

.z-cyan-10 {
  color: @cyan-10;
}

// Blue
.z-blue-1 {
  color: @blue-1;
}

.z-blue-2 {
  color: @blue-2;
}

.z-blue-3 {
  color: @blue-3;
}

.z-blue-4 {
  color: @blue-4;
}

.z-blue-5 {
  color: @blue-5;
}

.z-blue-6 {
  color: @blue-6;
}

.z-blue-7 {
  color: @blue-7;
}

.z-blue-8 {
  color: @blue-8;
}

.z-blue-9 {
  color: @blue-9;
}

.z-blue-10 {
  color: @blue-10;
}

// GeekBlue
.z-geekblue-1 {
  color: @geekblue-1;
}

.z-geekblue-2 {
  color: @geekblue-2;
}

.z-geekblue-3 {
  color: @geekblue-3;
}

.z-geekblue-4 {
  color: @geekblue-4;
}

.z-geekblue-5 {
  color: @geekblue-5;
}

.z-geekblue-6 {
  color: @geekblue-6;
}

.z-geekblue-7 {
  color: @geekblue-7;
}

.z-geekblue-8 {
  color: @geekblue-8;
}

.z-geekblue-9 {
  color: @geekblue-9;
}

.z-geekblue-10 {
  color: @geekblue-10;
}

// Purple 
.z-purple-1 {
  color: @purple-1;
}

.z-purple-2 {
  color: @purple-2;
}

.z-purple-3 {
  color: @purple-3;
}

.z-purple-4 {
  color: @purple-4;
}

.z-purple-5 {
  color: @purple-5;
}

.z-purple-6 {
  color: @purple-6;
}

.z-purple-7 {
  color: @purple-7;
}

.z-purple-8 {
  color: @purple-8;
}

.z-purple-9 {
  color: @purple-9;
}

.z-purple-10 {
  color: @purple-10;
}

// Magenta

.z-magenta-1 {
  color: @magenta-1;
}

.z-magenta-2 {
  color: @magenta-2;
}

.z-magenta-3 {
  color: @magenta-3;
}

.z-magenta-4 {
  color: @magenta-4;
}

.z-magenta-5 {
  color: @magenta-5;
}

.z-magenta-6 {
  color: @magenta-6;
}

.z-magenta-7 {
  color: @magenta-7;
}

.z-magenta-8 {
  color: @magenta-8;
}

.z-magenta-9 {
  color: @magenta-9;
}

.z-magenta-10 {
  color: @magenta-10;
}