.ant-table {
  .ant-table-column-sorter {
    margin-left: 4px;
  }

  .ant-table-thead>tr>th {
    color         : @text-color-secondary;
    text-transform: uppercase;
  }

  .ant-table-thead>tr:not(:last-child)>th[colspan] {
    border-bottom: solid 1px @border-color-split;
  }

  &.ant-table-bordered {
    .ant-table-container {
      border-bottom-left-radius : @border-radius-base;
      border-bottom-right-radius: @border-radius-base;
    }

    .ant-table-tbody {
      .ant-table-row:last-child {
        .ant-table-cell:first-child {
          border-bottom-left-radius: @border-radius-base;
        }

        .ant-table-cell:last-child {
          border-bottom-right-radius: @border-radius-base;
        }
      }
    }
  }
}