.ant-notification {
  .ant-notification-notice-error {

    .ant-notification-notice-message,
    .ant-notification-notice-description {
      color: @error-color;

      .ant-typography {
        color: @error-color;
      }
    }
  }
}