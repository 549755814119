.zTableTreeLevelLinesPlaceholder() {
  >td:first-child {
    &:before {
      position   : absolute;
      width      : 1px;
      top        : 0;
      bottom     : 0;
      border-left: 1px solid @border-color-split;
    }

    &:after {
      position     : absolute;
      width        : 8px;
      top          : 0;
      bottom       : ~"calc(50% - 1px)";
      border-bottom: 1px solid @border-color-split;
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    &.ant-table-middle {

      .ant-table-thead>tr>th.z-action-button-cell,
      .ant-table-tbody>tr>td.z-action-button-cell {
        padding   : (@table-padding-vertical-md/2);
        text-align: center;
      }
    }

    &.ant-table-small {

      .ant-table-thead>tr>th.z-action-button-cell,
      .ant-table-tbody>tr>td.z-action-button-cell {
        padding   : (@table-padding-vertical-sm/2);
        text-align: center;
      }
    }
  }

  .ant-table-middle+.ant-table-pagination {
    margin-top   : @table-padding-vertical-md;
    margin-bottom: @table-padding-vertical-md;
  }

  .ant-table-small+.ant-table-pagination {
    margin-top   : @table-padding-vertical-sm;
    margin-bottom: @table-padding-vertical-sm;
  }

  &.z-ant-table-border-outer {
    .ant-table {
      border       : solid 1px @border-color-split;
      border-radius: @border-radius-base;

      .ant-table-tbody {
        >tr:last-child {
          >td {
            border-bottom: none;
          }
        }
      }

      .ant-table-footer {
        border-top: solid 1px @border-color-split;
      }
    }
  }

  &.z-ant-table-tree-lines-placeholder {
    .ant-table {
      .ant-table-tbody {
        >tr {
          &.ant-table-row-level-0 {
            .zTableTreeLevelLinesPlaceholder();
          }

          &.ant-table-row-level-1 {
            .zTableTreeLevelLinesPlaceholder();
          }

          &.ant-table-row-level-2 {
            .zTableTreeLevelLinesPlaceholder();
          }
        }
      }
    }
  }
}

.z-ant-table-scroll {
  .ant-table {
    overflow: auto;
  }
}