// Grey
.z-bg-grey-1 {
  background-color: @grey-1;
}

.z-bg-grey-2 {
  background-color: @grey-2;
}

.z-bg-grey-3 {
  background-color: @grey-3;
}

.z-bg-grey-4 {
  background-color: @grey-4;
}

.z-bg-grey-5 {
  background-color: @grey-5;
}

.z-bg-grey-6 {
  background-color: @grey-6;
}

.z-bg-grey-7 {
  background-color: @grey-7;
}

.z-bg-grey-8 {
  background-color: @grey-8;
}

.z-bg-grey-9 {
  background-color: @grey-9;
}

.z-bg-grey-10 {
  background-color: @grey-10;
}

// Primary
.z-bg-primary-1 {
  background-color: @primary-1;
}

.z-bg-primary-2 {
  background-color: @primary-2;
}

.z-bg-primary-3 {
  background-color: @primary-3;
}

.z-bg-primary-4 {
  background-color: @primary-4;
}

.z-bg-primary-5 {
  background-color: @primary-5;
}

.z-bg-primary-6 {
  background-color: @primary-6;
}

.z-bg-primary-7 {
  background-color: @primary-7;
}

.z-bg-primary-8 {
  background-color: @primary-8;
}

.z-bg-primary-9 {
  background-color: @primary-9;
}

.z-bg-primary-10 {
  background-color: @primary-10;
}

// Red
.z-bg-red-1 {
  background-color: @red-1;
}

.z-bg-red-2 {
  background-color: @red-2;
}

.z-bg-red-3 {
  background-color: @red-3;
}

.z-bg-red-4 {
  background-color: @red-4;
}

.z-bg-red-5 {
  background-color: @red-5;
}

.z-bg-red-6 {
  background-color: @red-6;
}

.z-bg-red-7 {
  background-color: @red-7;
}

.z-bg-red-8 {
  background-color: @red-8;
}

.z-bg-red-9 {
  background-color: @red-9;
}

.z-bg-red-10 {
  background-color: @red-10;
}

// Volcano
.z-bg-volcano-1 {
  background-color: @volcano-1;
}

.z-bg-volcano-2 {
  background-color: @volcano-2;
}

.z-bg-volcano-3 {
  background-color: @volcano-3;
}

.z-bg-volcano-4 {
  background-color: @volcano-4;
}

.z-bg-volcano-5 {
  background-color: @volcano-5;
}

.z-bg-volcano-6 {
  background-color: @volcano-6;
}

.z-bg-volcano-7 {
  background-color: @volcano-7;
}

.z-bg-volcano-8 {
  background-color: @volcano-8;
}

.z-bg-volcano-9 {
  background-color: @volcano-9;
}

.z-bg-volcano-10 {
  background-color: @volcano-10;
}

// Orange
.z-bg-orange-1 {
  background-color: @orange-1;
}

.z-bg-orange-2 {
  background-color: @orange-2;
}

.z-bg-orange-3 {
  background-color: @orange-3;
}

.z-bg-orange-4 {
  background-color: @orange-4;
}

.z-bg-orange-5 {
  background-color: @orange-5;
}

.z-bg-orange-6 {
  background-color: @orange-6;
}

.z-bg-orange-7 {
  background-color: @orange-7;
}

.z-bg-orange-8 {
  background-color: @orange-8;
}

.z-bg-orange-9 {
  background-color: @orange-9;
}

.z-bg-orange-10 {
  background-color: @orange-10;
}

// Gold
.z-bg-gold-1 {
  background-color: @gold-1;
}

.z-bg-gold-2 {
  background-color: @gold-2;
}

.z-bg-gold-3 {
  background-color: @gold-3;
}

.z-bg-gold-4 {
  background-color: @gold-4;
}

.z-bg-gold-5 {
  background-color: @gold-5;
}

.z-bg-gold-6 {
  background-color: @gold-6;
}

.z-bg-gold-7 {
  background-color: @gold-7;
}

.z-bg-gold-8 {
  background-color: @gold-8;
}

.z-bg-gold-9 {
  background-color: @gold-9;
}

.z-bg-gold-10 {
  background-color: @gold-10;
}

// Yellow
.z-bg-yellow-1 {
  background-color: @yellow-1;
}

.z-bg-yellow-2 {
  background-color: @yellow-2;
}

.z-bg-yellow-3 {
  background-color: @yellow-3;
}

.z-bg-yellow-4 {
  background-color: @yellow-4;
}

.z-bg-yellow-5 {
  background-color: @yellow-5;
}

.z-bg-yellow-6 {
  background-color: @yellow-6;
}

.z-bg-yellow-7 {
  background-color: @yellow-7;
}

.z-bg-yellow-8 {
  background-color: @yellow-8;
}

.z-bg-yellow-9 {
  background-color: @yellow-9;
}

.z-bg-yellow-10 {
  background-color: @yellow-10;
}

// Lime
.z-bg-lime-1 {
  background-color: @lime-1;
}

.z-bg-lime-2 {
  background-color: @lime-2;
}

.z-bg-lime-3 {
  background-color: @lime-3;
}

.z-bg-lime-4 {
  background-color: @lime-4;
}

.z-bg-lime-5 {
  background-color: @lime-5;
}

.z-bg-lime-6 {
  background-color: @lime-6;
}

.z-bg-lime-7 {
  background-color: @lime-7;
}

.z-bg-lime-8 {
  background-color: @lime-8;
}

.z-bg-lime-9 {
  background-color: @lime-9;
}

.z-bg-lime-10 {
  background-color: @lime-10;
}

// Green
.z-bg-green-1 {
  background-color: @green-1;
}

.z-bg-green-2 {
  background-color: @green-2;
}

.z-bg-green-3 {
  background-color: @green-3;
}

.z-bg-green-4 {
  background-color: @green-4;
}

.z-bg-green-5 {
  background-color: @green-5;
}

.z-bg-green-6 {
  background-color: @green-6;
}

.z-bg-green-7 {
  background-color: @green-7;
}

.z-bg-green-8 {
  background-color: @green-8;
}

.z-bg-green-9 {
  background-color: @green-9;
}

.z-bg-green-10 {
  background-color: @green-10;
}

// Cyan
.z-bg-cyan-1 {
  background-color: @cyan-1;
}

.z-bg-cyan-2 {
  background-color: @cyan-2;
}

.z-bg-cyan-3 {
  background-color: @cyan-3;
}

.z-bg-cyan-4 {
  background-color: @cyan-4;
}

.z-bg-cyan-5 {
  background-color: @cyan-5;
}

.z-bg-cyan-6 {
  background-color: @cyan-6;
}

.z-bg-cyan-7 {
  background-color: @cyan-7;
}

.z-bg-cyan-8 {
  background-color: @cyan-8;
}

.z-bg-cyan-9 {
  background-color: @cyan-9;
}

.z-bg-cyan-10 {
  background-color: @cyan-10;
}

// Blue
.z-bg-blue-1 {
  background-color: @blue-1;
}

.z-bg-blue-2 {
  background-color: @blue-2;
}

.z-bg-blue-3 {
  background-color: @blue-3;
}

.z-bg-blue-4 {
  background-color: @blue-4;
}

.z-bg-blue-5 {
  background-color: @blue-5;
}

.z-bg-blue-6 {
  background-color: @blue-6;
}

.z-bg-blue-7 {
  background-color: @blue-7;
}

.z-bg-blue-8 {
  background-color: @blue-8;
}

.z-bg-blue-9 {
  background-color: @blue-9;
}

.z-bg-blue-10 {
  background-color: @blue-10;
}

// GeekBlue
.z-bg-geekblue-1 {
  background-color: @geekblue-1;
}

.z-bg-geekblue-2 {
  background-color: @geekblue-2;
}

.z-bg-geekblue-3 {
  background-color: @geekblue-3;
}

.z-bg-geekblue-4 {
  background-color: @geekblue-4;
}

.z-bg-geekblue-5 {
  background-color: @geekblue-5;
}

.z-bg-geekblue-6 {
  background-color: @geekblue-6;
}

.z-bg-geekblue-7 {
  background-color: @geekblue-7;
}

.z-bg-geekblue-8 {
  background-color: @geekblue-8;
}

.z-bg-geekblue-9 {
  background-color: @geekblue-9;
}

.z-bg-geekblue-10 {
  background-color: @geekblue-10;
}

// Purple 
.z-bg-purple-1 {
  background-color: @purple-1;
}

.z-bg-purple-2 {
  background-color: @purple-2;
}

.z-bg-purple-3 {
  background-color: @purple-3;
}

.z-bg-purple-4 {
  background-color: @purple-4;
}

.z-bg-purple-5 {
  background-color: @purple-5;
}

.z-bg-purple-6 {
  background-color: @purple-6;
}

.z-bg-purple-7 {
  background-color: @purple-7;
}

.z-bg-purple-8 {
  background-color: @purple-8;
}

.z-bg-purple-9 {
  background-color: @purple-9;
}

.z-bg-purple-10 {
  background-color: @purple-10;
}

// Magenta

.z-bg-magenta-1 {
  background-color: @magenta-1;
}

.z-bg-magenta-2 {
  background-color: @magenta-2;
}

.z-bg-magenta-3 {
  background-color: @magenta-3;
}

.z-bg-magenta-4 {
  background-color: @magenta-4;
}

.z-bg-magenta-5 {
  background-color: @magenta-5;
}

.z-bg-magenta-6 {
  background-color: @magenta-6;
}

.z-bg-magenta-7 {
  background-color: @magenta-7;
}

.z-bg-magenta-8 {
  background-color: @magenta-8;
}

.z-bg-magenta-9 {
  background-color: @magenta-9;
}

.z-bg-magenta-10 {
  background-color: @magenta-10;
}