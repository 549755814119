@import './color.less';
@import './background.less';
@import './typography.less';
@import './align.less';
@import './border.less';
@import './shadow.less';
@import './spacing.less';
@import './button.less';
@import './date-picker.less';
@import './select.less';
@import './card.less';
@import './drawer.less';
@import './form.less';
@import './menu.less';
@import './table.less';

.z-clickable {
  cursor: pointer;
}

.z-nowrap {
  white-space: nowrap;
}

.z-width-full {
  width: 100%;
}

.z-height-full {
  height: 100%;
}

.z-cursor-pointer {
  cursor: pointer;
}