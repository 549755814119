.ant-form-item-label {
  white-space: normal;

  >label {
    min-height: @input-height-base;
    height    : auto;
  }
}

.ant-form-item-has-error {
  .ant-input-group-addon:last-child {
    padding-right: 28px;
  }
}