.z-border-radius {
  border-radius: @border-radius-base;
}

.z-border-radius-sm {
  border-radius: @border-radius-sm;
}

.z-border-split {
  border: 1px solid @border-color-split;
}

.z-border-split-top {
  border-top: 1px solid @border-color-split;
}

.z-border-split-right {
  border-right: 1px solid @border-color-split;
}

.z-border-split-bottom {
  border-bottom: 1px solid @border-color-split;
}

.z-border-split-left {
  border-left: 1px solid @border-color-split;
}

.z-border-split-color {
  border-color: @border-color-split;
}

.z-border {
  border: 1px solid @border-color-base;
}

.z-border-top {
  border-top: 1px solid @border-color-base;
}

.z-border-right {
  border-right: 1px solid @border-color-base;
}

.z-border-bottom {
  border-bottom: 1px solid @border-color-base;
}

.z-border-left {
  border-left: 1px solid @border-color-base;
}

.z-border-color {
  border-color: @border-color-base;
}

.z-shadowed-border {
  box-shadow: 0 0 1px inset rgba(0, 0, 0, 0.1);
}

.z-border-split-dashed-bottom {
  border-bottom: 1px dashed @border-color-split;
}

.z-border-split-dashed-top {
  border-top: 1px dashed @border-color-split;
}

.z-border-split-dashed-left {
  border-left: 1px dashed @border-color-split;
}

.z-border-split-dashed-right {
  border-right: 1px dashed @border-color-split;
}

.z-border-dashed-bottom {
  border-bottom: 1px dashed @border-color-base;
}

.z-border-dashed-top {
  border-top: 1px dashed @border-color-base;
}

.z-border-dashed-left {
  border-left: 1px dashed @border-color-base;
}

.z-border-dashed-right {
  border-right: 1px dashed @border-color-base;
}

// Grey
.z-border-grey-1 {
  border: solid 1px @grey-1;
}

.z-border-grey-2 {
  border: solid 1px @grey-2;
}

.z-border-grey-3 {
  border: solid 1px @grey-3;
}

.z-border-grey-4 {
  border: solid 1px @grey-4;
}

.z-border-grey-5 {
  border: solid 1px @grey-5;
}

.z-border-grey-6 {
  border: solid 1px @grey-6;
}

.z-border-grey-7 {
  border: solid 1px @grey-7;
}

.z-border-grey-8 {
  border: solid 1px @grey-8;
}

.z-border-grey-9 {
  border: solid 1px @grey-9;
}

.z-border-grey-10 {
  border: solid 1px @grey-10;
}

.z-border-grey-11 {
  border: solid 1px @grey-10;
}

// Primary
.z-border-primary-1 {
  border: solid 1px @primary-1;
}

.z-border-primary-2 {
  border: solid 1px @primary-2;
}

.z-border-primary-3 {
  border: solid 1px @primary-3;
}

.z-border-primary-4 {
  border: solid 1px @primary-4;
}

.z-border-primary-5 {
  border: solid 1px @primary-5;
}

.z-border-primary-6 {
  border: solid 1px @primary-6;
}

.z-border-primary-7 {
  border: solid 1px @primary-7;
}

.z-border-primary-8 {
  border: solid 1px @primary-8;
}

.z-border-primary-9 {
  border: solid 1px @primary-9;
}

.z-border-primary-10 {
  border: solid 1px @primary-10;
}

// Red
.z-border-red-1 {
  border: solid 1px @red-1;
}

.z-border-red-2 {
  border: solid 1px @red-2;
}

.z-border-red-3 {
  border: solid 1px @red-3;
}

.z-border-red-4 {
  border: solid 1px @red-4;
}

.z-border-red-5 {
  border: solid 1px @red-5;
}

.z-border-red-6 {
  border: solid 1px @red-6;
}

.z-border-red-7 {
  border: solid 1px @red-7;
}

.z-border-red-8 {
  border: solid 1px @red-8;
}

.z-border-red-9 {
  border: solid 1px @red-9;
}

.z-border-red-10 {
  border: solid 1px @red-10;
}

// Volcano
.z-border-volcano-1 {
  border: solid 1px @volcano-1;
}

.z-border-volcano-2 {
  border: solid 1px @volcano-2;
}

.z-border-volcano-3 {
  border: solid 1px @volcano-3;
}

.z-border-volcano-4 {
  border: solid 1px @volcano-4;
}

.z-border-volcano-5 {
  border: solid 1px @volcano-5;
}

.z-border-volcano-6 {
  border: solid 1px @volcano-6;
}

.z-border-volcano-7 {
  border: solid 1px @volcano-7;
}

.z-border-volcano-8 {
  border: solid 1px @volcano-8;
}

.z-border-volcano-9 {
  border: solid 1px @volcano-9;
}

.z-border-volcano-10 {
  border: solid 1px @volcano-10;
}

// Orange
.z-border-orange-1 {
  border: solid 1px @orange-1;
}

.z-border-orange-2 {
  border: solid 1px @orange-2;
}

.z-border-orange-3 {
  border: solid 1px @orange-3;
}

.z-border-orange-4 {
  border: solid 1px @orange-4;
}

.z-border-orange-5 {
  border: solid 1px @orange-5;
}

.z-border-orange-6 {
  border: solid 1px @orange-6;
}

.z-border-orange-7 {
  border: solid 1px @orange-7;
}

.z-border-orange-8 {
  border: solid 1px @orange-8;
}

.z-border-orange-9 {
  border: solid 1px @orange-9;
}

.z-border-orange-10 {
  border: solid 1px @orange-10;
}

// Gold
.z-border-gold-1 {
  border: solid 1px @gold-1;
}

.z-border-gold-2 {
  border: solid 1px @gold-2;
}

.z-border-gold-3 {
  border: solid 1px @gold-3;
}

.z-border-gold-4 {
  border: solid 1px @gold-4;
}

.z-border-gold-5 {
  border: solid 1px @gold-5;
}

.z-border-gold-6 {
  border: solid 1px @gold-6;
}

.z-border-gold-7 {
  border: solid 1px @gold-7;
}

.z-border-gold-8 {
  border: solid 1px @gold-8;
}

.z-border-gold-9 {
  border: solid 1px @gold-9;
}

.z-border-gold-10 {
  border: solid 1px @gold-10;
}

// Yellow
.z-border-yellow-1 {
  border: solid 1px @yellow-1;
}

.z-border-yellow-2 {
  border: solid 1px @yellow-2;
}

.z-border-yellow-3 {
  border: solid 1px @yellow-3;
}

.z-border-yellow-4 {
  border: solid 1px @yellow-4;
}

.z-border-yellow-5 {
  border: solid 1px @yellow-5;
}

.z-border-yellow-6 {
  border: solid 1px @yellow-6;
}

.z-border-yellow-7 {
  border: solid 1px @yellow-7;
}

.z-border-yellow-8 {
  border: solid 1px @yellow-8;
}

.z-border-yellow-9 {
  border: solid 1px @yellow-9;
}

.z-border-yellow-10 {
  border: solid 1px @yellow-10;
}

// Lime
.z-border-lime-1 {
  border: solid 1px @lime-1;
}

.z-border-lime-2 {
  border: solid 1px @lime-2;
}

.z-border-lime-3 {
  border: solid 1px @lime-3;
}

.z-border-lime-4 {
  border: solid 1px @lime-4;
}

.z-border-lime-5 {
  border: solid 1px @lime-5;
}

.z-border-lime-6 {
  border: solid 1px @lime-6;
}

.z-border-lime-7 {
  border: solid 1px @lime-7;
}

.z-border-lime-8 {
  border: solid 1px @lime-8;
}

.z-border-lime-9 {
  border: solid 1px @lime-9;
}

.z-border-lime-10 {
  border: solid 1px @lime-10;
}

// Green
.z-border-green-1 {
  border: solid 1px @green-1;
}

.z-border-green-2 {
  border: solid 1px @green-2;
}

.z-border-green-3 {
  border: solid 1px @green-3;
}

.z-border-green-4 {
  border: solid 1px @green-4;
}

.z-border-green-5 {
  border: solid 1px @green-5;
}

.z-border-green-6 {
  border: solid 1px @green-6;
}

.z-border-green-7 {
  border: solid 1px @green-7;
}

.z-border-green-8 {
  border: solid 1px @green-8;
}

.z-border-green-9 {
  border: solid 1px @green-9;
}

.z-border-green-10 {
  border: solid 1px @green-10;
}

// Cyan
.z-border-cyan-1 {
  border: solid 1px @cyan-1;
}

.z-border-cyan-2 {
  border: solid 1px @cyan-2;
}

.z-border-cyan-3 {
  border: solid 1px @cyan-3;
}

.z-border-cyan-4 {
  border: solid 1px @cyan-4;
}

.z-border-cyan-5 {
  border: solid 1px @cyan-5;
}

.z-border-cyan-6 {
  border: solid 1px @cyan-6;
}

.z-border-cyan-7 {
  border: solid 1px @cyan-7;
}

.z-border-cyan-8 {
  border: solid 1px @cyan-8;
}

.z-border-cyan-9 {
  border: solid 1px @cyan-9;
}

.z-border-cyan-10 {
  border: solid 1px @cyan-10;
}

// Blue
.z-border-blue-1 {
  border: solid 1px @blue-1;
}

.z-border-blue-2 {
  border: solid 1px @blue-2;
}

.z-border-blue-3 {
  border: solid 1px @blue-3;
}

.z-border-blue-4 {
  border: solid 1px @blue-4;
}

.z-border-blue-5 {
  border: solid 1px @blue-5;
}

.z-border-blue-6 {
  border: solid 1px @blue-6;
}

.z-border-blue-7 {
  border: solid 1px @blue-7;
}

.z-border-blue-8 {
  border: solid 1px @blue-8;
}

.z-border-blue-9 {
  border: solid 1px @blue-9;
}

.z-border-blue-10 {
  border: solid 1px @blue-10;
}

// GeekBlue
.z-border-geekblue-1 {
  border: solid 1px @geekblue-1;
}

.z-border-geekblue-2 {
  border: solid 1px @geekblue-2;
}

.z-border-geekblue-3 {
  border: solid 1px @geekblue-3;
}

.z-border-geekblue-4 {
  border: solid 1px @geekblue-4;
}

.z-border-geekblue-5 {
  border: solid 1px @geekblue-5;
}

.z-border-geekblue-6 {
  border: solid 1px @geekblue-6;
}

.z-border-geekblue-7 {
  border: solid 1px @geekblue-7;
}

.z-border-geekblue-8 {
  border: solid 1px @geekblue-8;
}

.z-border-geekblue-9 {
  border: solid 1px @geekblue-9;
}

.z-border-geekblue-10 {
  border: solid 1px @geekblue-10;
}

// Purple 
.z-border-purple-1 {
  border: solid 1px @purple-1;
}

.z-border-purple-2 {
  border: solid 1px @purple-2;
}

.z-border-purple-3 {
  border: solid 1px @purple-3;
}

.z-border-purple-4 {
  border: solid 1px @purple-4;
}

.z-border-purple-5 {
  border: solid 1px @purple-5;
}

.z-border-purple-6 {
  border: solid 1px @purple-6;
}

.z-border-purple-7 {
  border: solid 1px @purple-7;
}

.z-border-purple-8 {
  border: solid 1px @purple-8;
}

.z-border-purple-9 {
  border: solid 1px @purple-9;
}

.z-border-purple-10 {
  border: solid 1px @purple-10;
}

// Magenta

.z-border-magenta-1 {
  border: solid 1px @magenta-1;
}

.z-border-magenta-2 {
  border: solid 1px @magenta-2;
}

.z-border-magenta-3 {
  border: solid 1px @magenta-3;
}

.z-border-magenta-4 {
  border: solid 1px @magenta-4;
}

.z-border-magenta-5 {
  border: solid 1px @magenta-5;
}

.z-border-magenta-6 {
  border: solid 1px @magenta-6;
}

.z-border-magenta-7 {
  border: solid 1px @magenta-7;
}

.z-border-magenta-8 {
  border: solid 1px @magenta-8;
}

.z-border-magenta-9 {
  border: solid 1px @magenta-9;
}

.z-border-magenta-10 {
  border: solid 1px @magenta-10;
}