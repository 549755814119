strong {
  font-weight: 600;
}

.z-font-strong {
  font-weight: 600;
}

.z-capitalize {
  text-transform: capitalize;
}

.z-font-size {
  font-size: @font-size-base;
}

.z-font-size-xl {
  font-size: @font-size-lg + 4px;
}

.z-font-size-lg {
  font-size: @font-size-lg;
}

.z-font-size-sm {
  font-size: @font-size-sm;
}

.z-font-size-xs {
  font-size: 8px;
}

.z-color-default {
  color: @text-color;
}

.z-color-primary {
  color: @primary-color;
}

.z-color-secondary {
  color: @text-color-secondary;
}

.z-color-secondary-dark {
  color: @text-color-secondary-dark;
}

.z-color-danger {
  color: @error-color;
}

.z-color-warning {
  color: @warning-color;
}

.z-color-white {
  color: @white;
}

.z-color-success {
  color: @success-color;
}

.z-uppercase {
  text-transform: uppercase;
}

.z-lowercase {
  text-transform: lowercase;
}

.z-wrap {
  white-space: normal;
}

.z-nowrap {
  white-space: nowrap;
}

.z-align-right {
  text-align: right;
}

.z-text-background-brush {
  position   : relative;
  z-index    : 0;
  white-space: nowrap;

  &::before {
    content         : '';
    background-color: rgba(180, 180, 180, 0.3);
    position        : absolute;
    height          : 60%;
    width           : ~"calc(100% - 5%)";
    bottom          : 0;
    left            : 5%;
    border-radius   : 8px;
    z-index         : -1;
  }
}