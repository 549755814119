.ant-drawer {
  &.z-app-header-in-backdrop {
    >.ant-drawer-mask {
      background-color: transparent;
      cursor          : not-allowed;
    }

    >.ant-drawer-content-wrapper {
      top       : @layout-header-height;
      height    :~"calc(100% - @{layout-header-height})";
      box-shadow: none;
      border-top: solid 1px @border-color-split;
    }
  }

  &.z-full-screen {
    >.ant-drawer-content-wrapper {
      top       : 0;
      height    : 100%;
      box-shadow: none;
      border-top: solid 1px @border-color-split;
    }
  }

  &.z-drawer-transparent {
    >.ant-drawer-mask {
      background-color: transparent;
    }

    >.ant-drawer-content-wrapper {
      background-color: transparent;

      >.ant-drawer-content {
        background-color: transparent;
      }
    }
  }
}