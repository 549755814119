.gradient {
  background-image: linear-gradient(to right, @primary-color, @z-primary-gradient-color);
}

.ant-btn-primary:not(:disabled):not(:hover):not(:focus):not(:active),
.ant-checkbox-checked .ant-checkbox-inner,
.ant-switch-checked,
.ant-badge-count {
  .gradient();
}

.ant-btn-primary {
  &:hover {
    background-image: linear-gradient(to right, darken(@primary-color, 5%), darken(@z-primary-gradient-color, 5%));
  }
}

.z-linear-gradient-primary {
  .gradient();
}