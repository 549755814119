.z-input-padding-vertical {
  padding-top   : @input-padding-vertical-base;
  padding-bottom: @input-padding-vertical-base;
}

.z-input-padding-horizontal {
  padding-left : @input-padding-horizontal;
  padding-right: @input-padding-horizontal;
}

.z-input-padding-top {
  padding-top: @input-padding-vertical-base;
}

.z-input-padding-right {
  padding-right: @input-padding-horizontal;
}

.z-input-padding-bottom {
  padding-bottom: @input-padding-vertical-base;
}

.z-input-padding-left {
  padding-left: @input-padding-horizontal;
}

.z-input-padding-vertical-lg {
  padding-top   : @input-padding-vertical-lg;
  padding-bottom: @input-padding-vertical-lg;
}

.z-input-padding-horizontal-lg {
  padding-left : @input-padding-vertical-lg;
  padding-right: @input-padding-vertical-lg;
}


.z-input-padding-vertical-sm {
  padding-top   : @input-padding-vertical-sm;
  padding-bottom: @input-padding-vertical-sm;
}

.z-input-padding-horizontal-sm {
  padding-left : @input-padding-vertical-sm;
  padding-right: @input-padding-vertical-sm;
}

.z-input-padding-top-sm {
  padding-top: @input-padding-vertical-sm;
}

.z-input-padding-right-sm {
  padding-right: @input-padding-vertical-sm;
}

.z-input-padding-bottom-sm {
  padding-bottom: @input-padding-vertical-sm;
}

.z-input-padding-left-sm {
  padding-left: @input-padding-vertical-sm;
}

.z-input-padding-top-lg {
  padding-top: @input-padding-vertical-lg;
}

.z-input-padding-right-lg {
  padding-right: @input-padding-vertical-lg;
}

.z-input-padding-bottom-lg {
  padding-bottom: @input-padding-vertical-lg;
}

.z-input-padding-left-lg {
  padding-left: @input-padding-vertical-lg;
}

.z-form-section {
  padding-bottom: @padding-sm;
}