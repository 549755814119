.z-box-shadow-up {
  box-shadow: @shadow-1-up;
}

.z-box-shadow-right {
  box-shadow: @shadow-1-right;
}

.z-box-shadow-down {
  box-shadow: @shadow-1-down;
}

.z-box-shadow-left {
  box-shadow: @shadow-1-left;
}

.z-box-shadow {
  box-shadow: @shadow-2;
}