.ant-menu-dark.z-main-side-menu-dark {
  .ant-menu-submenu-open {
    color: @text-color-secondary-dark;

    .ant-menu-submenu-title>.ant-menu-submenu-arrow {

      &::before,
      &::after {
        background: @text-color-secondary-dark;
      }
    }

    &:hover {
      .ant-menu-submenu-title>.ant-menu-submenu-arrow {

        &::before,
        &::after {
          background: @menu-dark-selected-item-icon-color;
        }
      }
    }
  }
}