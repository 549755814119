.z-button-success {
  background-color: @green-6;
  border-color    : @green-6;
  background-image: linear-gradient(to right, @green-6, darken(@green-6, 5%));

  &:hover,
  &:focus {
    background-color: @green-6;
    border-color    : @green-6;
    background-image: linear-gradient(to right, @green-6, @green-7);
  }

  >span {
    color: @white;
  }
}

.z-button-success-border {
  border-color: @green-6;

  &:hover,
  &:focus {
    border-color: @green-6;

    >span {
      color: @green-6;
    }
  }

  >span {
    color: @green-6;
  }
}

.z-button-danger {
  background-color: @red-6;
  border-color    : @red-6;
  background-image: linear-gradient(to right, @red-6, darken(@red-6, 5%));

  &:hover,
  &:focus {
    background-color: @red-6;
    border-color    : @red-6;
    background-image: linear-gradient(to right, @red-6, @red-7);
  }

  >span {
    color: @white;
  }
}

.z-button-danger-border {
  border-color: @red-6;

  &:hover,
  &:focus {
    border-color: @red-6;

    >span {
      color: @red-6;
    }
  }

  >span {
    color: @red-6;
  }
}